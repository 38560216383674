// src/components/LandingPage.js
import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './LandingPage.css'; // Custom CSS
import { useNavigate } from 'react-router-dom';
import TradingCategories from './TradingCategories';

const LandingPage = () => {
    const navigate = useNavigate();

  return (
    <Container fluid className="landing-page py-3">
      {/* Title Section */}
      <Row className="justify-content-center text-center mb-1">
        <Col md={8} xs={12}>
          <h1 className="landing-title">Start Investing with a Few Easy Steps</h1>
          <p className="landing-subtitle">
            Follow these simple steps to start earning with secure and hassle-free investments.
          </p>
        </Col>
      </Row>

      {/* Steps Section */}
      <TradingCategories />

        <Row className="text-center">
            <Col lg={4} md={6} xs={12} className="mb-4">
            <Card className="step-card h-100">
                <Card.Body>
                <Card.Title>1. Complete your KYC</Card.Title>
                <Card.Text>Secure your investment with a quick KYC verification.</Card.Text>
                </Card.Body>
            </Card>
            </Col>

            <Col lg={4} md={6} xs={12} className="mb-4">
            <Card className="step-card h-100">
                <Card.Body>
                <Card.Title>2. Select an amount to invest</Card.Title>
                <Card.Text>Choose the investment amount that suits you.</Card.Text>
                </Card.Body>
            </Card>
            </Col>

            <Col lg={4} md={6} xs={12} className="mb-4">
            <Card className="step-card h-100">
                <Card.Body>
                <Card.Title>3. Get returns over 700 days</Card.Title>
                <Card.Text>Earn returns over a period of 700 days.</Card.Text>
                </Card.Body>
            </Card>
            </Col>
        
            <Col lg={4} md={6} xs={12} className="mb-4">
            <Card className="step-card h-100">
                <Card.Body>
                <Card.Title>4. Monthly 5% Interest Rate</Card.Title>
                <Card.Text>Enjoy a fixed monthly interest rate of 5% on your investment.</Card.Text>
                </Card.Body>
            </Card>
            </Col>

            <Col lg={4} md={6} xs={12} className="mb-4">
            <Card className="step-card h-100">
                <Card.Body>
                <Card.Title>5. Simple Interest</Card.Title>
                <Card.Text>We calculate interest based on simple interest for transparency.</Card.Text>
                </Card.Body>
            </Card>
            </Col>

            <Col lg={4} md={6} xs={12} className="mb-4">
            <Card className="step-card h-100">
                <Card.Body>
                <Card.Title>6. Withdraw profit amount between 1 to 2 of every month</Card.Title>
                <Card.Text>Withdraw your profits at the beginning of each month.</Card.Text>
                </Card.Body>
            </Card>
            </Col>
        
            <Col lg={6} md={8} xs={12} className="mb-4 mx-auto">
            <Card className="step-card h-100">
                <Card.Body>
                <Card.Title>7. Withdraw principal amount after maturity</Card.Title>
                <Card.Text>Once your investment matures, withdraw the principal amount with ease.</Card.Text>
                </Card.Body>
            </Card>
            </Col>
        </Row>

      

      {/* Call to Action */}
      <Row className="justify-content-center text-center mt-5">
        <Col md={6} xs={12}>
          <Button variant="primary" size="lg" className="cta-button" onClick={() => navigate('/login')}>
            Start Investing Now
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPage;
