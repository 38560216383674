import React, { useState } from 'react';
import { Form, Button, Col, Row, Container, Card } from 'react-bootstrap';
import './BankDetails.css'; // Import the CSS file for custom styles
import { db } from '../../firebase';
import { doc, setDoc, getDoc, updateDoc, query, where, collection, getDocs } from 'firebase/firestore';

const BankDetails = ({ userDetails }) => {
  const [bankDetails, setBankDetails] = useState({
    bankName: userDetails.bankDetails ? userDetails.bankDetails.bankName : '',
    bankAccount: userDetails.bankDetails ? userDetails.bankDetails.bankAccount : '',
    ifscCode: userDetails.bankDetails ? userDetails.bankDetails.ifscCode : '',
    panCard: userDetails.bankDetails ? userDetails.bankDetails.panCard : '',
    aadhaar: userDetails.bankDetails ? userDetails.bankDetails.aadhaar : ''
  });


  const handleChange = (e) => {
    setBankDetails({
      ...bankDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
  
      // Find the user document by email
        const usersCollectionRef = collection(db, 'users');
        const emailQuery = query(usersCollectionRef, where('email', '==', userDetails.email));
        const querySnapshot = await getDocs(emailQuery);

        if (!querySnapshot.empty) {
        // Assuming the email is unique, we update the first document found
        const userDocRef = querySnapshot.docs[0].ref;
        await updateDoc(userDocRef, {
            bankDetails: {
                bankName: bankDetails.bankName,
                bankAccount: bankDetails.bankAccount,
                ifscCode: bankDetails.ifscCode,
                panCard: bankDetails.panCard,
                aadhaar: bankDetails.aadhaar,
            }, 
            kyc: false
        });
        }
      alert('Bank details updated');
    } catch (error) {
      console.error('Error updating bank details:', error);
      alert('There was an error updating your details.');
    }
  };

  return (
    <Container className="bank-details-container d-flex justify-content-center align-items-center">
      <Card className="bank-details-card p-4">
        <Form onSubmit={handleSubmit}>
          {/* KYC Status Toggle */}
          <Form.Group as={Row} controlId="formKYC" className="mb-4">
            <Form.Label column sm={4}>
              KYC Status
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="switch"
                id="kyc-toggle"
                label={userDetails.kyc ? 'Completed' : 'Pending'}
                checked={userDetails.kyc}
              />
            </Col>
          </Form.Group>

          {/* Bank Name */}
          <Form.Group as={Row} controlId="formBankName" className="mb-4">
            <Form.Label column sm={4}>Bank Name</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="bankName"
                placeholder="Enter your bank name"
                value={bankDetails.bankName}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>

          {/* Bank Account Number */}
          <Form.Group as={Row} controlId="formBankAccount" className="mb-4">
            <Form.Label column sm={4}>Account Number</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="bankAccount"
                placeholder="Enter your account number"
                value={bankDetails.bankAccount}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>

          {/* IFSC Code */}
          <Form.Group as={Row} controlId="formIFSC" className="mb-4">
            <Form.Label column sm={4}>IFSC Code</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="ifscCode"
                placeholder="Enter IFSC code"
                value={bankDetails.ifscCode}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>

          {/* PAN Card */}
          <Form.Group as={Row} controlId="formPanCard" className="mb-4">
            <Form.Label column sm={4}>PAN Card</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="panCard"
                placeholder="Enter your PAN Card number"
                value={bankDetails.panCard}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>

          {/* AADHAAR Card */}
          <Form.Group as={Row} controlId="formAadhaarCard" className="mb-4">
            <Form.Label column sm={4}>Aadhaar Card</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="aadhaar"
                placeholder="Enter your Aadhaar Card number"
                value={bankDetails.aadhaar}
                onChange={handleChange}
                required
              />
            </Col>
          </Form.Group>

          {/* Submit Button */}
          <div className="text-center">
            <Button type="submit" className="mt-3 w-50" variant="primary">
              Update Details
            </Button>
          </div>
        </Form>
      </Card>
    </Container>
  );
};

export default BankDetails;
