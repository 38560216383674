// src/components/Login.js
import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase'; // Import auth and Firestore
import { getDocs, collection, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import the CSS file for custom styles

const Login = () => {
  const [formData, setFormData] = useState({
    phone: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Function to query Firestore to get email by phone number
  const getEmailByPhone = async (phone) => {
    try {
      const usersRef = collection(db, 'users');
      const phoneQuery = query(usersRef, where('phone', '==', phone));
      const querySnapshot = await getDocs(phoneQuery);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        return userData.email; // return the email associated with the phone
      } else {
        alert('No user found with this phone number.');
        throw new Error('No user found with this phone number.');
      }
    } catch (err) {
      console.error('Error fetching email by phone:', err);
      throw err; // Throw error to be handled in login function
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { phone, password } = formData;
    const email  = await getEmailByPhone(phone);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home'); // Redirect to home page on successful login
    } catch (err) {
      setError(err.message); // Set error message if login fails
    }
  };

  return (
    <div className="login-page">
      <Container className="login-container">
        <h2 className="text-center">Log In</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formPhone">
          <Form.Control
            type="text"
            name="phone"
            placeholder="Enter your phone number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword" className="mt-3">
          <Form.Control
            type="password"
            name="password"
            placeholder="Enter your password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-4 w-100">
          Login
        </Button>
      </Form>
      <p style={{marginTop:'8px'}}>New to application ? <a href='/signup'>Register here</a></p>
    </Container>
    </div>
  );
};

export default Login;
