import React from 'react';
import './MovingText.css';
import { useLocation } from 'react-router-dom';

const categories = [
    { text: '20% Forex Trading' },    // Light Peach
    { text: '20% MCX Trading'},      // Light Blue
    { text: '20% Nasdaq American Market'},  // Light Green
    { text: '20% Nifty 50 Indian Market' },  // Light Orange
    { text: '20% Silver & Gold Only Dubai'} // Light Yellow
];

const MovingText = () => {
    const location = useLocation();

    if (location.pathname == '/') {
        return <></>
    }

    return (
        <div className="scroll-container">
            <div className="scroll-text">
                {categories.map((category, index) => (
                    <span
                        key={index}
                        className="category-item"
                        style={{ backgroundColor: '#cf9e62', color:'white' }}
                    >
                        {category.text}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default MovingText;
