import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Tab, Tabs, Form, Button, Card, Spinner } from 'react-bootstrap';
import { db } from '../../firebase';
import { getDocs, collection, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import './AdminView.css'; // Optional custom CSS
import AdminInvestment from './AdminInvestment';
import AdminWithdrawals from './AdminWithdrawals';
import AdminHistory from './AdminHistory';
import axios from 'axios';

function AdminView({ userDetails }) {
  const [usersBankDetails, setUsersBankDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [kycFilter, setKycFilter] = useState('all'); // State for KYC filter
  const [loading, setLoading] = useState(true);

  const fetchBankDetails = async () => {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const users = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setUsersBankDetails(users.filter(user => user.admin === false));
    setLoading(false)
  };

  // Fetch all users bank details from Firestore
  useEffect(() => {
    setLoading(true)
    fetchBankDetails();
  }, []);

  // Function to approve user bank details (set KYC status to true)
  const approveBankDetails = async (userId) => {
    setLoading(true)
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      kyc: true,
    });
    setLoading(false)
    fetchBankDetails()
  };

  // Function to approve user bank details (set KYC status to true)
  const deleteUser = async (user) => {
    if (!user.email || !user.id) {
      alert('User not found')
      return
    }
    setLoading(true)
    console.log(user);
    try {
      const userEmail = user.email
      const response = await axios.delete('https://api.emiratesearngrow.com/delete-user', {
        data: { email: userEmail }, // You can send the data in the body for DELETE requests
      });

      // First, delete the user from Firestore
      const userDocRef = doc(db, 'users', user.id);
      await deleteDoc(userDocRef);
      console.log(response.data.message);
      alert('User deleted successfully !')
    } catch (error) {
      console.error('Error deleting user:', error);
    }
    // const userRef = doc(db, 'users', userId);
    // await updateDoc(userRef, {
    //   kyc: true,
    // });
    setLoading(false)
    fetchBankDetails()
  };

  // Filter users based on the search term and KYC status
  const filteredUsers = usersBankDetails.filter(user => {
    const matchesSearch = user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          user.name?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesKyc = 
      kycFilter === 'all' || 
      (kycFilter === 'approved' && user.kyc) || 
      (kycFilter === 'pending' && !user.kyc);
    return matchesSearch && matchesKyc;
  });

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <Container fluid className="admin-view">
      {/* Admin Header */}
      <Row className="align-items-center mb-4">
        <Col md={6}>
          <h2>Welcome Admin, {userDetails?.name}</h2>
        </Col>
        <Col md={6}>
          <Form className="d-flex justify-content-end">
            <Form.Control
              type="search"
              placeholder="Search by email or name"
              className="me-2"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form>
        </Col>
      </Row>

      {/* Admin Tabs */}
      <Row>
        <Col>
          <Tabs defaultActiveKey="investments" id="admin-tabs" className="mb-3" fill>
            <Tab eventKey="investments" title="Investments">
              <AdminInvestment searchTerm={searchTerm} setLoading={setLoading}/> {/* Include the AdminInvestment component */}
            </Tab>
            <Tab eventKey="withdraw" title="Withdrawal Requests">
              <AdminWithdrawals searchTerm={searchTerm}/>
            </Tab>
            <Tab eventKey="investment-history" title="History">
              {/* Add Investment History Tab Content */}
              <AdminHistory />
            </Tab>
            <Tab eventKey="bank-details" title="Users Bank Details">
              {/* Admin Bank Details Tab */}
              <div className="bank-details-tab">

                {/* Dropdown for KYC Filter */}
                <Form.Group controlId="kycFilter" className="mb-3" style={{width:'200px'}}>
                  <Form.Select value={kycFilter} onChange={(e) => setKycFilter(e.target.value)}>
                    <option value="all">All</option>
                    <option value="approved">KYC Approved</option>
                    <option value="pending">KYC Pending</option>
                  </Form.Select>
                </Form.Group>
                
                <Row>
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map((user) => (
                      <Col md={3} xs={12} key={user.id}>
                        <Card className="mb-3">
                          <Card.Body>
                            <Card.Title>{user.name}</Card.Title>
                            <Card.Text>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p><strong>Email</strong></p>
                                <p>{user.email}</p>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p><strong>Phone</strong></p>
                                <p>{user.phone}</p>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p><strong>Bank Name</strong></p>
                                <p>{user.bankDetails?.bankName}</p>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p><strong>IFSC Code</strong></p>
                                <p>{user.bankDetails?.ifscCode}</p>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p><strong>Pan Card</strong></p>
                                <p>{user.bankDetails?.panCard}</p>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p><strong>KYC Status</strong></p>
                                <p>{user.kyc ? 'Approved' : 'Pending'}</p>
                              </div>
                              
                            </Card.Text>
                              <Button variant="success" onClick={() => approveBankDetails(user.id)} disabled={user.kyc}>
                                Approve Bank Details
                              </Button>
                              <Button variant="danger" onClick={() => deleteUser(user)} className='m-1'>
                                Delete User
                              </Button>
                            
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <p>No bank details found.</p>
                  )}
                </Row>
              </div>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminView;
