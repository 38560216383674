import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Spinner, Alert, Form } from 'react-bootstrap';
import { collection, getDocs, updateDoc, doc, getDoc, where, query } from 'firebase/firestore';
import { db } from '../../firebase'; // Firebase configuration

const AdminWithdrawals = ({searchTerm}) => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all'); // State to track filter

  // Fetch all withdrawal requests from Firestore
  const fetchWithdrawals = async () => {
    try {
      setLoading(true);
      const withdrawCollectionRef = collection(db, 'withdraw');
      const querySnapshot = await getDocs(withdrawCollectionRef);

      const allWithdrawals = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const docData = doc.data();
        console.log(docData);

        const usersRef = collection(db, 'users');
        const emailQuery = query(usersRef, where('email', '==', docData.email));
        const querySnapshot = await getDocs(emailQuery);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();          
          // Return the withdrawal data along with the user's information
          return {
            id: doc.id,
            ...docData,
            name: userData.name,
            email: userData.email,
            phone: userData.phone,
          };
        } else {
          console.error('No such user found!');
          return {
            id: doc.id,
            ...docData,
            name: 'Unknown',
            email: 'Unknown',
            phone: 'Unknown',
          };
        }
      }));

      setWithdrawals(allWithdrawals);
    } catch (err) {
      console.error('Error fetching withdrawal requests:', err);
      setError('Failed to load withdrawal requests.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  // Approve a withdrawal request
  const approveWithdrawal = async (withdrawalId) => {
    try {
      const withdrawalRef = doc(db, 'withdraw', withdrawalId);
      await updateDoc(withdrawalRef, {
        withdrawApproved: true, // Set status to approved
      });
      alert('Withdrawal approved successfully!');
      fetchWithdrawals(); // Refresh the list after approval
    } catch (err) {
      console.error('Error approving withdrawal:', err);
      alert('Failed to approve withdrawal.');
    }
  };

  // Filter withdrawals based on selected filter
  const filteredWithdrawals = withdrawals.filter(withdrawal => 
    withdrawal.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .filter((withdrawal) => {
    if (filter === 'all') return true;
    if (filter === 'approved') return withdrawal.withdrawApproved === true;
    if (filter === 'notApproved') return withdrawal.withdrawApproved === false;
    return true;
  });

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div>

      {/* Filter dropdown */}
      <Form.Group controlId="withdrawalFilter" className="mb-3" style={{ width: '200px' }}>
        <Form.Select value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="all">All</option>
          <option value="approved">Approved</option>
          <option value="notApproved">Not Approved</option>
        </Form.Select>
      </Form.Group>

      <Row>
        {filteredWithdrawals.length > 0 ? (
          filteredWithdrawals.map((withdrawal) => (
            <Col md={4} key={withdrawal.id} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>Requested by {withdrawal.name}</Card.Title>
                  <Card.Text>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Phone Number</strong></p>
                      <p>{withdrawal.phone}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Email</strong></p>
                      <p>{withdrawal.email}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Amount</strong></p>
                      <p>{parseFloat(withdrawal.amount).toFixed(2)}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Request Date</strong></p>
                      <p>{withdrawal.requestedDate.toDate().toLocaleDateString('en-US', {
                          month: 'short',  // Abbreviated month (e.g., "Oct")
                          day: '2-digit',  // Day of the month with two digits (e.g., "01")
                          year: 'numeric'  // Full year (e.g., "2024")
                        })}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Status</strong></p>
                      <p>{withdrawal.withdrawApproved ? 'Approved' : 'Not Approved'}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Type</strong></p>
                      <p>{withdrawal.type}</p>
                    </div>
                  </Card.Text>
                  <Button variant="success" onClick={() => approveWithdrawal(withdrawal.id)} disabled={withdrawal.withdrawApproved}>
                    {'Approve Withdrawal'}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p>No withdrawal requests found.</p>
        )}
      </Row>
    </div>
  );
};

export default AdminWithdrawals;
