import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Card, Row, Col, Modal } from 'react-bootstrap';
import { addDoc, query, where, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { LuCalendarDays } from "react-icons/lu";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { MdOutlineTimelapse } from "react-icons/md";
import { Timestamp } from 'firebase/firestore';
import QRCode from '../../images/QRCode.jpg'
import { MdAddCircleOutline } from "react-icons/md";
import emailjs from '@emailjs/browser';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Investments = ({ userDetails }) => {
  const [investments, setInvestments] = useState([]);
  const [filter, setFilter] = useState('all');
  const [totalInvested, setTotalInvested] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalPastProfit, settotalPastProfit] = useState(0)

  const fetchAllInvestmentForUser = async () => {
    const usersCollectionRef = collection(db, 'users');
    const emailQuery = query(usersCollectionRef, where('email', '==', userDetails.email));
    const querySnapshot = await getDocs(emailQuery);

    if (!querySnapshot.empty) {
      const userDocRef = querySnapshot.docs[0].ref;
      const investmentsRef = collection(userDocRef, 'investments');
      const investmentSnapshot = await getDocs(investmentsRef);

      const userInvestments = investmentSnapshot.docs.map(doc => {
        const investment = doc.data();
        const { amount, interest, createdDate, lastWithdrawDate } = investment;

        // Convert Firestore timestamps to JavaScript Date objects
        const createdDateMs = createdDate.toDate().getTime();
        const lastWithdrawDateMs = lastWithdrawDate ? lastWithdrawDate.toDate().getTime() : null;

        // Compare the two dates and get the latest one (use createdDate if lastWithdrawDate is missing)
        const profitDate = lastWithdrawDateMs && lastWithdrawDateMs > createdDateMs ? lastWithdrawDateMs : createdDateMs;

        // 1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
        const millisecondsInADay = 24 * 60 * 60 * 1000;

        // Calculate the timestamp for 700 days later
        const dateAfter700days = createdDateMs + (700 * millisecondsInADay);

        // Get the current date in milliseconds
        const currentDateMs = new Date().getTime();

        // Compare dateAfter700days with currentDateMs and select the smaller one
        const cutoffDateMs = dateAfter700days < currentDateMs ? dateAfter700days : currentDateMs;

        const daysSinceLastTrans = Math.floor((cutoffDateMs - profitDate) / (1000 * 60 * 60 * 24));
        const daysSinceInvestment = Math.floor((currentDateMs - createdDateMs) / (1000 * 60 * 60 * 24));

        // Calculate profit based on the days since investment
        const profit = (amount * interest * daysSinceLastTrans) / (100 * 30); // assuming interest is monthly

        let pastProfit = 0
        if (lastWithdrawDateMs) {
          const daysTillLastTrans = Math.floor((lastWithdrawDateMs - createdDateMs) / (1000 * 60 * 60 * 24));
          pastProfit = (amount * interest * daysTillLastTrans) / (100 * 30);
        }


        // Calculate the total number of days since the start (from the createdDate)
        const fromStartDate = Math.floor((currentDateMs - createdDateMs) / (1000 * 60 * 60 * 24));

        // Calculate days left assuming a 700-day investment period
        const daysLeft = 700 - daysSinceInvestment;

        // Calculate total amount after maturity
        const maturityAmount = Number(amount) + (amount * interest * 700) / (100 * 30)

        // Return the calculated fields along with the original investment data
        return {
          id: doc.id,
          ...investment,
          profit,
          daysCompleted: daysSinceInvestment,
          daysLeft: daysLeft >= 0 ? daysLeft : 0,  // Ensure daysLeft doesn't go negative
          fromStartDate,
          profitDays: daysSinceLastTrans,
          maturityAmount,
          pastProfit
        };
      });

      setInvestments(userInvestments);
      calculateTotals(userInvestments);
    }
  };

  const calculateTotals = (userInvestments) => {
    const totalInvestedAmount = userInvestments.reduce((acc, investment) => acc + parseFloat(investment.amount), 0);
    const totalProfitAmount = userInvestments.reduce((acc, investment) => acc + parseFloat(investment.profit), 0);
    const totalPastProfitAmount = userInvestments.reduce((acc, investment) => acc + parseFloat(investment.pastProfit), 0);

    settotalPastProfit(totalPastProfitAmount.toFixed(2))
    setTotalInvested(totalInvestedAmount.toFixed(2));
    setTotalProfit(totalProfitAmount.toFixed(2));
  };

  useEffect(() => {
    fetchAllInvestmentForUser();
  }, []);


  const isFirstOrSecond = () => {
    const today = new Date();
    const dayOfMonth = today.getDate(); // Get the current day of the month
    return dayOfMonth === 1 || dayOfMonth === 2;
  };

  // Function to handle profit withdrawal and add to 'withdrawals' collection
  const handleWithdrawProfit = async (investment) => {

    if (!isFirstOrSecond()) {
      alert('You can withdraw profit only on 1st or 2nd date of every month !')
      return 
    }
      const withdrawalsRef = collection(db, 'withdraw'); // Reference to the withdrawals subcollection

      const newWithdrawal = {
        investmentId: investment.id,
        userId: userDetails.userId,
        email: userDetails.email,
        amount: investment.profit, // Withdraw the profit amount
        requestedDate: new Date(),
        type:'profit',
        profitEarned: investment.profit,
        withdrawApproved: false,
      };
      await addDoc(withdrawalsRef, newWithdrawal);

      const investmentDocRef = doc(db, 'users', userDetails.userId, 'investments', investment.id);
      await updateDoc(investmentDocRef, {
        lastWithdrawDate: new Date()
      });

      alert(`Profit of ₹${investment.profit.toFixed(2)} has been requested to withdrawn!`);
  };

  const handleWithdrawAmount = async (inv) => {
    const daysSinceInvestment = Math.floor((new Date() - inv.createdDate) / (1000 * 60 * 60 * 24));
    if (daysSinceInvestment < 700) {
      alert('You can withdraw total principal amount only after 700 days!');
    } else {
      const withdrawalsRef = collection(db, 'withdraw'); // Reference to the withdrawals subcollection

      const newWithdrawal = {
        investmentId: inv.id,
        userId: userDetails.userId,
        email: userDetails.email,
        amount: inv.amount, // Withdraw the profit amount
        requestedDate: new Date(),
        type:'Principal',
        withdrawApproved: false,
      };
      await addDoc(withdrawalsRef, newWithdrawal);

      const investmentDocRef = doc(db, 'users', userDetails.userId, 'investments', inv.id);
      await updateDoc(investmentDocRef, {
        lastWithdrawDate: new Date()
      });

      alert(`Amount of ₹${inv.amount.toFixed(2)} has been requested to withdrawn!`);
    }
  };

  const sortedInvestments = investments.sort((a, b) => {
    return b.createdDate.seconds - a.createdDate.seconds;
  });

  const [show, setShow] = useState(false);

  // Functions to handle modal open and close
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const InvestmentModal = () => {
    const [investmentAmount, setInvestmentAmount] = useState('');
    const [utrno, setUtrno] = useState('')

    const handleAddInvestment = async (e) => {
      e.preventDefault();
      if (investmentAmount) {
        const usersCollectionRef = collection(db, 'users');
        const emailQuery = query(usersCollectionRef, where('email', '==', userDetails.email));
        const querySnapshot = await getDocs(emailQuery);
  
        if (!querySnapshot.empty) {
          const userDocRef = querySnapshot.docs[0].ref;
          const investmentsRef = collection(userDocRef, 'investments');
  
          const newInvestment = {
            amount: parseFloat(investmentAmount).toFixed(2),
            createdDate: new Date(),
            interest: 5,
            lastWithdrawDate: null,
            approvedDate: null,
            approved: false,
            principalWithdraw: false,
            completed: false,
            utrno: utrno,
          };
  
          await addDoc(investmentsRef, newInvestment);
          console.log("Initial investment document added!");
  
          setInvestments(prevInvestments => [...prevInvestments, newInvestment]);
          setInvestmentAmount('');
  
          calculateTotals([...investments, newInvestment]);
          fetchAllInvestmentForUser()
          handleClose()
          if (utrno) {
            // sendEmail(newInvestment);
          }
        }
      }
    };

    return (
      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Payment Form</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <p><b>Scan QR Code to pay your funds !</b></p>
          <img 
            src={QRCode}
            alt="Payment QR Code" 
            className="img-fluid mb-4" 
            style={{ width: '200px' }} 
          />
        {/* Center the form fields and button */}
        <Form onSubmit={handleAddInvestment} className="d-flex flex-column align-items-center">
          {/* Investment Amount Input */}
          <Form.Group controlId="investmentAmount" className="mb-3" style={{ width: '100%' }}>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              value={investmentAmount}
              onChange={(e) => {
                e.preventDefault();
                setInvestmentAmount(e.target.value)
              }}
              required
            />
          </Form.Group>

          {/* UTR Number Input */}
          <Form.Group controlId="utrno" className="mb-3" style={{ width: '100%' }}>
            <Form.Control
              type="text"
              placeholder="Enter UTR No"
              value={utrno}
              onChange={(e) => setUtrno(e.target.value)}
              required
            />
          </Form.Group>

          {/* Centered Button */}
          <Button variant="primary" type="submit" className="w-100">
            Submit
          </Button>
        </Form>
      </Modal.Body>
          
      </Modal>
    )
  }

  const filteredInvestments = sortedInvestments.filter((investment) => {
    if (filter === 'approved') return investment.approved === true;
    if (filter === 'unapproved') return investment.approved === false;
    if (filter === 'completed') return investment.completed === true;
    return true;
  });

  return (
    <div>
      <InvestmentModal />
      <Row className="mb-4" style={{ justifyContent: 'space-between' }}>
        <Col md={3} xs={12}>
        <Button variant="primary" onClick={handleShow}>
        <MdAddCircleOutline /> Add Funds
        </Button>
        </Col>

        <Col md={3} xs={12}>
          <Card>
            <Card.Body>
              <Card.Text style={{marginBottom:0}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p><strong>Client Name</strong></p>
                  <p>{userDetails.name}</p>
                </div>
              </Card.Text>
              <Card.Text style={{marginBottom:0}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p><strong>Client ID</strong></p>
                  <p>{userDetails.clientId ? userDetails.clientId : ''}</p>
                </div>
              </Card.Text>
              <Card.Text style={{marginBottom:0}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p><strong>Total Investment</strong></p>
                  <p>{totalInvested}</p>
                </div>
              </Card.Text>
              <Card.Text style={{marginBottom:0}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p><strong>Total Current Profit</strong></p>
                  <p>{totalProfit}</p>
                </div>
              </Card.Text>
              <Card.Text style={{marginBottom:0}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p><strong>Total Past Profit</strong></p>
                  <p>{totalPastProfit}</p>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3} xs={12} className="d-flex align-items-center">
          <Form.Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="all">All Investments</option>
            <option value="approved">Approved Investments</option>
            <option value="unapproved">Unapproved Investments</option>
            <option value="completed">Completed</option>
          </Form.Select>
        </Col>
      </Row>

      <Row className='d-flex justify-content-center'>
        {filteredInvestments.length > 0 ? (
          filteredInvestments.map((investment) => (
            <Col md={4} sm={6} xs={12} key={investment.id} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Text>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Investment Amount</strong></p>
                      <p><LiaRupeeSignSolid />{investment.amount}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Payment UTR</strong></p>
                      <p>{investment.utrno ? investment.utrno : ''}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Start Date</strong></p>
                      <p><LuCalendarDays /> {investment.createdDate && (
                        // Check if createdDate is a Firestore Timestamp
                        investment.createdDate instanceof Timestamp
                          ? investment.createdDate.toDate().toLocaleDateString('en-US', {
                              month: 'short',  // Abbreviated month (e.g., "Oct")
                              day: '2-digit',   // Day of the month with two digits (e.g., "01")
                              year: 'numeric'   // Full year (e.g., "2024")
                            })
                          : new Date(investment.createdDate).toLocaleDateString('en-US', {
                              month: 'short',
                              day: '2-digit',
                              year: 'numeric'
                            })
                      )}
                      </p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Interest rate</strong></p>
                      <p>{investment.interest}%</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Profit Earned</strong></p>
                      <p><LiaRupeeSignSolid />{parseFloat(investment.profit).toFixed(2) ?? 0} - <MdOutlineTimelapse />{investment.profitDays}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Days Completed</strong></p>
                      <p><MdOutlineTimelapse />{investment.daysCompleted}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Days Left</strong></p>
                      <p><MdOutlineTimelapse />{investment.daysLeft}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Last Withdrawal</strong></p>
                      <p><LuCalendarDays />{investment.lastWithdrawDate && investment.lastWithdrawDate.toDate().toLocaleDateString('en-US', {
                                              month: 'short',  // Abbreviated month (e.g., "Oct")
                                              day: '2-digit',  // Day of the month with two digits (e.g., "01")
                                              year: 'numeric'  // Full year (e.g., "2024")
                                            })}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Approval Status</strong></p>
                      <p>{investment.approved ? 'Approved' : 'Pending'}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Amount After maturity</strong></p>
                      <p><LiaRupeeSignSolid />{parseFloat(investment.maturityAmount).toFixed(2)}</p>
                    </div>
                  </Card.Text>
                  <Button 
                    className='m-1' 
                    disabled={!investment.approved || investment.profit <= 0}
                    onClick={() => handleWithdrawProfit(investment)}
                  >
                    Withdraw Profit
                  </Button>
                  <Button 
                    className='m-1' 
                    disabled={!investment.approved || (investment.fromStartDate < 700)} 
                    onClick={() => handleWithdrawAmount(investment)}
                  >
                    Withdraw Amount
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p>No investments to display.</p>
        )}
      </Row>
    </div>
  );
};

export default Investments;
