// src/components/Signup.js
import React, { useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import './Signup.css'; // Import the CSS file for custom styles
import { auth,db } from '../firebase'; // Import Firebase authentication
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, getDocs, collection, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import JSBI from 'jsbi';

const Signup = () => {
    const [error, setError] = useState('')
    const navigate = useNavigate(); // Initialize navigate
    const [emailExists, setEmailExists] = useState(false);
    const [phoneExists, setPhoneExists] = useState(false);
    const [users, setUsers] = useState([]);
    
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setEmailExists(false); // Reset emailExists when user types
    setPhoneExists(false);
  };

  const generateUserId = (phoneNumber) => {
    // Step 1: Create a SHA-256 hash of the phone number
    const hash = CryptoJS.SHA256(phoneNumber).toString(CryptoJS.enc.Hex);

    // Step 2: Convert the hexadecimal hash to a Big Integer (base 10) using JSBI
    const numericHash = JSBI.BigInt('0x' + hash);  // Use JSBI instead of native BigInt

    // Step 3: Use modulo to reduce the numeric value to a specific range (e.g., 9 digits)
    const userId = JSBI.remainder(numericHash, JSBI.BigInt(1000000000)).toString();  // Modulo 10^9 for a 9-digit number

    return userId;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.password.length < 8) {
        alert('Password Length should be greater than 8 !')
    } else if (formData.confirmPassword == formData.password) {
      const { email, phone, password } = formData;

      // First, check if the email or phone is already registered
      const emailPhoneCheck = await checkEmailOrPhoneExists(email.toLowerCase(), phone);
      if (emailPhoneCheck.emailExists) {
        setEmailExists(true);
        alert('Email address already registered')
        return;
      }
      if (emailPhoneCheck.phoneExists) {
        setPhoneExists(true);
        alert('Phone Number address already registered')
        return;
      }
        
        try {
            // Firebase authentication function to create a new user
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const bankDetails = {
              bankAccount: '',
              bankName: '',
              ifscCode: '',
              panCard: ''
            }

            const clientId = generateUserId(phone);

            // Save user data to Firestore
            await setDoc(doc(db, 'users', user.uid), {
                name: formData.name,
                email: email.toLowerCase(),
                phone: formData.phone,
                admin: false,
                kyc: false,
                bankDetails,
                createdAt: new Date(),
                clientId: 'EEG'+clientId
            });

            navigate('/home'); // Redirect to the home page after signup
            console.log('User signed up:', userCredential.user);
          } catch (err) {
            setError(err.message);
            console.error('Signup error:', err);
          }
    }
    console.log('Signup Data:', formData);
  };

  const checkEmailOrPhoneExists = async (email, phone) => {
    const usersRef = collection(db, 'users');

    // Query Firestore for users with the same email
    const emailQuery = query(usersRef, where('email', '==', email));
    const emailQuerySnapshot = await getDocs(emailQuery);

    // Query Firestore for users with the same phone number
    const phoneQuery = query(usersRef, where('phone', '==', phone));
    const phoneQuerySnapshot = await getDocs(phoneQuery);

    return {
      emailExists: !emailQuerySnapshot.empty,
      phoneExists: !phoneQuerySnapshot.empty,
    };
  };

  return (
    <div className="signup-page">
      <Container className="signup-container">
        <h2 className="text-center">Register Now</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Control
              required
              type="text"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formEmail" className="mt-3">
            <Form.Control
            required
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formPhone" className="mt-3">
            <Form.Control
            required
              type="text"
              name="phone"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formPassword" className="mt-3">
            <Form.Control
            required
              type="password"
              name="password"
              placeholder="Enter your Password"
              value={formData.password}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formConfirmPassword" className="mt-3">
            <Form.Control
            required
              type="password"
              name="confirmPassword"
              placeholder="Confirm your Password"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-4 w-100">
            Register User
          </Button>
        </Form>
        <p style={{marginTop:'8px'}}>Already have an account ? <a href='/login'>LogIn here</a></p>
      </Container>
    </div>
  );
};

export default Signup;
