import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { LuCalendarDays } from "react-icons/lu";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { MdOutlineTimelapse } from "react-icons/md";

const AdminInvestment = ({ searchTerm, setLoading }) => {
  const [allInvestments, setAllInvestments] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all'); // Added filter state

  // Fetch all investments from Firestore
  const fetchAllInvestments = async () => {
    const investmentsCollectionRef = collection(db, 'users');
    const querySnapshot = await getDocs(investmentsCollectionRef);
    const investments = [];

    // Iterate over each user to get their investments
    for (const doc of querySnapshot.docs) {
      const user = doc.data();

      if (user.admin === false) { // Exclude admin users
        const userInvestmentsRef = collection(db, 'users', doc.id, 'investments');
        const userInvestmentsSnapshot = await getDocs(userInvestmentsRef);
        userInvestmentsSnapshot.forEach(investmentDoc => {
          const investment = investmentDoc.data();
          const { amount, interest, createdDate, lastWithdrawDate } = investment;

          // Convert Firestore timestamps to JavaScript Date objects
          const createdDateMs = createdDate.toDate().getTime();
          const lastWithdrawDateMs = lastWithdrawDate ? lastWithdrawDate.toDate().getTime() : null;

          // Compare the two dates and get the latest one (use createdDate if lastWithdrawDate is missing)
          const profitDate = lastWithdrawDateMs && lastWithdrawDateMs > createdDateMs ? lastWithdrawDateMs : createdDateMs;

          // Calculate the number of days since the latest profit date
          const currentDateMs = new Date().getTime();
          const daysSinceInvestment = Math.floor((currentDateMs - profitDate) / (1000 * 60 * 60 * 24));

          // Calculate profit based on the days since investment
          const profit = (amount * interest * daysSinceInvestment) / (100 * 30); // assuming interest is monthly

          // Calculate the total number of days since the start (from the createdDate)
          const fromStartDate = Math.floor((currentDateMs - createdDateMs) / (1000 * 60 * 60 * 24));

          // Calculate days left assuming a 700-day investment period
          const daysLeft = 700 - daysSinceInvestment;

          const formatedCreateDate = (investment.createdDate.toDate().toLocaleDateString('en-US', {
            month: 'short',  // Abbreviated month (e.g., "Oct")
            day: '2-digit',  // Day of the month with two digits (e.g., "01")
            year: 'numeric'  // Full year (e.g., "2024")
          }));
          
          
          investments.push({
            id: investmentDoc.id,
            ...investment, 
            userId: doc.id, 
            investmentId: investmentDoc.id,  // Save investment document ID for updates
            userName: user.name, 
            clientId: user.clientId ?? '',
            phone: user.phone,
            profit,
            daysCompleted: daysSinceInvestment,
            daysLeft: daysLeft >= 0 ? daysLeft : 0,  // Ensure daysLeft doesn't go negative
            formatedCreateDate
          });
        });
      }
    }

    setAllInvestments(investments);
    setLoading(false)
  };

  // Function to approve investment
  const handleApproveInvestment = async (investment) => {
    setLoading(true)
    try {
      // Reference to the specific user's investment document
      const investmentDocRef = doc(db, 'users', investment.userId, 'investments', investment.investmentId);
      
      // Update the 'approved' field to true
      await updateDoc(investmentDocRef, {
        approved: true,
        approvedDate: new Date()
      });
      
      // Display an alert to confirm approval
      alert(`Investment approved for ${investment.userName}`);
      
      // Refresh investments after approval
      fetchAllInvestments();

    } catch (error) {
      console.error('Error approving investment: ', error);
      alert('Failed to approve investment. Please try again.');
    }
  };

  useEffect(() => {
    fetchAllInvestments();
  }, []);

  // Filter investments based on the search term and approval status
  const sortedInvestments = allInvestments.sort((a, b) => {
    return b.createdDate.seconds - a.createdDate.seconds;
  });
  const filteredInvestments = sortedInvestments
    .filter(investment => 
      investment.userName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(investment => {
      if (filterStatus === 'approved') {
        return investment.approved === true;
      } else if (filterStatus === 'unapproved') {
        return investment.approved === false;
      }
      return true; // Show all for 'all' filter
    });

  return (
    <div>
      {/* Filter Dropdown */}
      <Form.Group controlId="filterStatus" className="mb-3" style={{ width: '200px' }}>
        <Form.Select value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
          <option value="all">All Investments</option>
          <option value="approved">Approved Investments</option>
          <option value="unapproved">Unapproved Investments</option>
        </Form.Select>
      </Form.Group>

      <Row>
        {filteredInvestments.length > 0 ? (
          filteredInvestments.map((investment, index) => (
            <Col md={4} xs={12} key={index}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>{investment.userName}</Card.Title>
                  <Card.Text>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Phone Number</strong></p>
                      <p>{investment.phone}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>User Name</strong></p>
                      <p>{investment.userName}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Client ID</strong></p>
                      <p>{investment.clientId}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Created Date</strong></p>
                      <p><LuCalendarDays /> {investment.formatedCreateDate}
                      </p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Investment Amount</strong></p>
                      <p><LiaRupeeSignSolid />{investment.amount}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Payment UTR</strong></p>
                      <p>{investment.utrno ? investment.utrno : ''}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Interest rate</strong></p>
                      <p>{investment.interest}%</p>
                    </div>
                    
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Profit</strong></p>
                      <p>{parseFloat(investment.profit).toFixed(2)}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Days Left</strong></p>
                      <p><MdOutlineTimelapse />{investment.daysLeft}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Approval Status</strong></p>
                      <p>{investment.approved ? 'Approved' : 'Pending'}</p>
                    </div>
                  </Card.Text>
                    <Button className='m-1' variant="success" 
                    disabled={investment.approved}
                    onClick={() => handleApproveInvestment(investment)}>
                      Approve Investment
                    </Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p>No investments found.</p>
        )}
      </Row>
    </div>
  );
};

export default AdminInvestment;
