import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Table, Spinner, Alert } from 'react-bootstrap';

const Withdrawals = ({ userDetails }) => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all withdrawal requests for the user
  const fetchWithdrawals = async () => {
    try {
      setLoading(true);
      const withdrawCollectionRef = collection(db, 'withdraw');
      const withdrawQuery = query(withdrawCollectionRef, where('userId', '==', userDetails.userId));
      const querySnapshot = await getDocs(withdrawQuery);

      const userWithdrawals = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

        setWithdrawals(userWithdrawals);

    } catch (err) {
      console.error('Error fetching withdrawals:', err);
      setError('Failed to load withdrawals.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div>
      <h4>Your Withdrawal Requests</h4>
      {withdrawals.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Withdrawal Amount</th>
              <th>Request Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {withdrawals.map((withdrawal, index) => (
              <tr key={withdrawal.id}>
                <td>{index + 1}</td>
                <td>{parseFloat(withdrawal.amount).toFixed(2)}</td>
                <td>{withdrawal.requestedDate.toDate().toLocaleDateString('en-US', {
                                              month: 'short',  // Abbreviated month (e.g., "Oct")
                                              day: '2-digit',  // Day of the month with two digits (e.g., "01")
                                              year: 'numeric'  // Full year (e.g., "2024")
                                            })}</td>
                <td>{withdrawal.withdrawApproved ? 'Approved' : 'Not Approved'}</td> {/* e.g., Pending, Approved, Rejected */}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No withdrawal requests found.</p>
      )}
    </div>
  );
};

export default Withdrawals;
