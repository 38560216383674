// src/components/Navbar.js
import React from 'react';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Import Firebase auth
import { BiUserCircle } from "react-icons/bi";
import './AppNavbar.css'; // Custom CSS
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { FcAbout } from "react-icons/fc";

const AppNavbar = ({user, userDetails}) => {
    const navigate = useNavigate();

    const handleSignupClick = () => {
        navigate('/signup');
    };

    const handleLogOut = async () => {
        try {
          await auth.signOut(); // Sign out user from Firebase
          navigate('/'); // Redirect to login page after logout
          } catch (error) {
          console.error('Logout error:', error);
        }
    }
    
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
      <Container>
        {/* Application Name */}
        <Navbar.Brand href="/">Emirates Earn Grow</Navbar.Brand>
        
        {/* Hamburger Icon for Mobile View */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        
        {/* Navbar Links (collapse on mobile) */}
        <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
        {user == null ? (
            <Nav className="ml-auto">
                {/* Login and Signup Buttons */}
                <Button variant="outline-light" className="m-2" onClick={() => navigate('/login')}>
                Log In
                </Button>
                <Button variant="primary" className="m-2" onClick={handleSignupClick}>
                Register Now
                </Button>
            </Nav>
        ) : (
            <Nav className="ml-auto">
                {userDetails !== null && userDetails.admin == true && (
                  <Nav.Item className="d-flex align-items-center user-info m-2">
                    <MdOutlineAdminPanelSettings size={24} className="me-2" color='white'/>
                    <span>Admin Panel</span>
                  </Nav.Item>
                )}
                <Nav.Item className="d-flex align-items-center user-info m-2">
                  <BiUserCircle size={24} className="me-2" color='white'/>
                  <span>{userDetails !== null ? userDetails.name : user.email}</span>
                </Nav.Item>
                <Nav.Item className="d-flex align-items-center user-info m-2">
                  <FaHome size={24} className="me-2" color='white'/>
                  <span onClick={() => navigate('/home')}>Home</span>
                </Nav.Item>
                <Nav.Item className="d-flex align-items-center user-info m-2">
                  <FcAbout size={24} className="me-2" color='white'/>
                  <span onClick={() => navigate('/aboutus')}>About Us</span>
                </Nav.Item>
                <Button variant="outline-light" className="m-2" onClick={handleLogOut}>
                    Log out
                </Button>
            </Nav>
        )}

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
