import React, { useEffect, useState } from 'react';
import './App.css';
import AppNavbar from './components/AppNavbar';
import LandingPage from './components/LandingPage';
import Signup from './components/Signup';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import { auth, db } from './firebase';
import Login from './components/Login';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { Spinner, Container } from 'react-bootstrap'; // Import Spinner and Container
import MovingText from './components/MovingText';
import AboutUs from './components/AboutUs';

function App() {
  const [user, setUser] = useState(null); // Store the user state
  const [userDetails, setUserDetails] = useState(null)
  const [loading, setLoading] = useState(true); // Add loading state


  // Function to query Firestore to get details by email
  const getUserDetailsByEmail = async (email) => {
    try {
      const usersRef = collection(db, 'users');
      const emailQuery = query(usersRef, where('email', '==', email));
      const querySnapshot = await getDocs(emailQuery);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        userData.userId = querySnapshot.docs[0].id
        
        return userData; // return the email associated with the email
      } else {
        alert('No user found with this Email.');
        throw new Error('No user found with this Email.');
      }
    } catch (err) {
      console.error('Error fetching details by email:', err);
      throw err; // Throw error to be handled in login function
    }
  };

  useEffect(() => {
    // Check for auth state change
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user && user.email) {
        const details = await getUserDetailsByEmail(user.email)
        setUserDetails(details)        
      }
      setLoading(false); // Set loading to false after user data is fetched
      console.log(user);
    });

    // Clean up the subscription
    return () => unsubscribe();
  }, []);

  if (loading) { // Show loader while loading
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" />
      </Container>
    );
  }
  return (
    <Router>
      <div className="App">
        <AppNavbar user={user} userDetails={userDetails}/>
        <MovingText />
        <Routes>
          <Route path="/" element={user ? <Navigate to="/home" /> : <LandingPage />} />
          <Route path="/signup" element={user ? <Navigate to="/home" /> : <Signup />} />
          <Route path="/login" element={user ? <Navigate to="/home" /> : <Login />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/home" element={user ? <Home user={user} userDetails={userDetails} loading={loading} setLoading={setLoading}/> : <Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
