import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Spinner, Alert, Form, Table } from 'react-bootstrap';
import { collection, getDocs, updateDoc, doc, getDoc, where, query } from 'firebase/firestore';
import { db } from '../../firebase'; // Firebase configuration


function History({userDetails}) {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const fetchUserTransactionHistory = async () => {
      try {
        setLoading(true);
        const withdrawCollectionRef = collection(db, 'withdraw');
        const q = query(withdrawCollectionRef, where("email", "==", userDetails.email));
        
        // Get the matching documents
        const querySnapshot = await getDocs(q);
  
        const allWithdrawals = await Promise.all(querySnapshot.docs.map(async (doc) => {
          const docData = doc.data();
          console.log(docData);
  
            if (docData.withdrawApproved) {
              return {
                  id: doc.id,
                  ...docData,
                  sent: false,
                  hdate: docData.requestedDate
                };
            }
        }));

        let userInvestments = []
        const usersCollectionRef = collection(db, 'users');
        const emailQuery = query(usersCollectionRef, where('email', '==', userDetails.email));
        const userQuerySnapshot = await getDocs(emailQuery);

        if (!userQuerySnapshot.empty) {
            const userDocRef = userQuerySnapshot.docs[0].ref;
            const investmentsRef = collection(userDocRef, 'investments');
            const investmentSnapshot = await getDocs(investmentsRef);

            userInvestments = investmentSnapshot.docs.map(doc => {
            const investment = doc.data();

            // Return the calculated fields along with the original investment data
            return {
                id: doc.id,
                ...investment,
                sent: true,
                hdate: investment.createdDate
                };
            });

        }
  
        setHistory(prevHistory => [...allWithdrawals, ...userInvestments]);
      } catch (err) {
        console.error('Error fetching withdrawal History:', err);
        setError('Failed to load withdrawal history.');
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
        fetchUserTransactionHistory();
    }, []);
  
  
    console.log(history);
    
    if (loading) {
      return <Spinner animation="border" variant="primary" />;
    }
  
    if (error) {
      return <Alert variant="danger">{error}</Alert>;
    }
  
    return (
        <div>

        <Row>
          {history.length > 0 ? (
                <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Withdrawal Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((h, index) => h && (
                    <tr key={h.id}>
                      <td>{index + 1}</td>
                      <td>{parseFloat(h.amount).toFixed(2)}</td>
                      <td>{h.hdate.toDate().toLocaleDateString('en-US', {
                                                    month: 'short',  // Abbreviated month (e.g., "Oct")
                                                    day: '2-digit',  // Day of the month with two digits (e.g., "01")
                                                    year: 'numeric'  // Full year (e.g., "2024")
                                                  })}</td>
                      <td>{h.sent ? 'Sent' : 'Received'}</td> 
                    </tr>
                  ))}
                </tbody>
              </Table>
          ) : (
            <p>No Transaction History found.</p>
          )}
        </Row>
      </div>
    );
  };


export default History