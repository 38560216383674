import React from 'react';
import { Tabs, Tab, Container, Spinner } from 'react-bootstrap';
import BankDetails from './BankDetails';
import Investments from './Investment';
import Withdrawals from './Withdrawals';
import History from './History';

const UserView = ({ userDetails }) => {
    
  return (
    <Container className="mt-4">
      <h2>Welcome, {userDetails.name}</h2>
      <Tabs defaultActiveKey="investments" id="user-view-tabs" className="mb-3" fill>
        {/* Investments Tab */}
        <Tab eventKey="investments" title="Investments">
          <div className="tab-content">
           <Investments userDetails={userDetails}/>
          </div>
        </Tab>

        {/* Withdraw Tab */}
        <Tab eventKey="withdraw" title="Withdrawals">
          <div className="tab-content">
            <Withdrawals userDetails={userDetails}/>
          </div>
        </Tab>

        {/* Bank Details Tab */}
        <Tab eventKey="bank-details" title="Bank Details">
            <BankDetails userDetails={userDetails} />
        </Tab>

        {/* History Tab */}
        <Tab eventKey="history" title="Transaction History">
          <div className="tab-content">
            <History userDetails={userDetails}/>
          </div>
        </Tab>

      </Tabs>
    </Container>
  );
};

export default UserView;
