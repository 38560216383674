import React from 'react'
import AdminView from './users/AdminView'
import UserView from './users/UserView'

function Home({user, userDetails, loading, setLoading}) {
    
    if (userDetails !== null) {
        if (userDetails.admin == true) {
            return <AdminView userDetails={userDetails}/>
        } else {
            return <UserView userDetails={userDetails} />
        }
    }
  return (
    <div>No User Found</div>
  )
}

export default Home