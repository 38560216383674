import React from 'react';
import { Row } from 'react-bootstrap';
// Example of a box component with different styles for each item
const TradingCategories = () => {
  // Define the categories and their respective colors
  const categories = [
    { text: '20% Forex Trading', color: '#FFDDC1' }, // Light Peach
    { text: '20% MCX Trading', color: '#C1E1FF' },   // Light Blue
    { text: '20% Nasdaq American Market', color: '#C1FFD7' }, // Light Green
    { text: '20% Nifty 50 Indian Market', color: '#FFE7C1' },  // Light Orange
    { text: '20% Silver & Gold Only Dubai', color: '#FFF1C1' } // Light Yellow
  ];

  return (
    <Row className="justify-content-center text-center mb-1">
      <div style={styles.container}>
      {categories.map((category, index) => (
        <div key={index} style={{ ...styles.box, backgroundColor: category.color }}>
          {category.text}
        </div>
      ))}
    </div>
    </Row>
  );
};

// Define styles for the component
const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    justifyContent: 'space-around',
    padding: '10px',
    width: '60%'
  },
  box: {
    width: '150px',
    padding: '10px',
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
  }
};

export default TradingCategories;
