// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; 

const firebaseConfig = {
    apiKey: "AIzaSyDmAArvB-wxhpmX1Er9cPqx-9ceAICxJJ8",
    authDomain: "emirates-earn-grow.firebaseapp.com",
    projectId: "emirates-earn-grow",
    storageBucket: "emirates-earn-grow.appspot.com",
    messagingSenderId: "980417560526",
    appId: "1:980417560526:web:b2417f6caa960059aef59f",
    measurementId: "G-GMVS0HZVJL"
  };

  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };