import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Spinner, Alert, Form } from 'react-bootstrap';
import { collection, getDocs, updateDoc, doc, getDoc, where, query } from 'firebase/firestore';
import { db } from '../../firebase'; // Firebase configuration

const AdminHistory = ({searchTerm}) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchWithdrawalsHistory = async () => {
    try {
      setLoading(true);
      const withdrawCollectionRef = collection(db, 'withdraw');
      const querySnapshot = await getDocs(withdrawCollectionRef);

      const allWithdrawals = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const docData = doc.data();
        console.log(docData);

        const usersRef = collection(db, 'users');
        const emailQuery = query(usersRef, where('email', '==', docData.email));
        const querySnapshot = await getDocs(emailQuery);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();          
          // Return the withdrawal data along with the user's information
          if (docData.withdrawApproved) {
            return {
                id: doc.id,
                ...docData,
                name: userData.name,
                email: userData.email,
                phone: userData.phone,
              };
          }
        }
      }));

      setHistory(allWithdrawals);
    } catch (err) {
      console.error('Error fetching withdrawal History:', err);
      setError('Failed to load withdrawal history.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdrawalsHistory();
  }, []);


  
  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div>

      <Row>
        {history.length > 0 ? (
          history.map((withdrawal) => ( withdrawal && 
            <Col md={4} key={withdrawal.id} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>{withdrawal.name}</Card.Title>
                  <Card.Text>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Phone Number</strong></p>
                      <p>{withdrawal.phone}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Email</strong></p>
                      <p>{withdrawal.email}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Amount</strong></p>
                      <p>{parseFloat(withdrawal.amount).toFixed(2)}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Request Date</strong></p>
                      <p>{withdrawal.requestedDate.toDate().toLocaleDateString('en-US', {
                            month: 'short',  // Abbreviated month (e.g., "Oct")
                            day: '2-digit',  // Day of the month with two digits (e.g., "01")
                            year: 'numeric'  // Full year (e.g., "2024")
                        })}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Status</strong></p>
                      <p>{withdrawal.withdrawApproved ? 'Approved' : 'Not Approved'}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p><strong>Type</strong></p>
                      <p>{withdrawal.type}</p>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p>No withdrawal History found.</p>
        )}
      </Row>
    </div>
  );
};

export default AdminHistory;
