import React from "react";
import { Container, Row, Col, Card, Button, ListGroup } from "react-bootstrap";

const AboutUs = () => {
  return (
    <Container className="my-5">
      <Row className="text-center mb-4">
        <Col>
          <h2 className="display-4">Emirates Star Coin</h2>
          <p className="lead text-muted">
            Where innovation meets opportunity in the world of digital currency.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col lg={8}>
          <Card className="p-4 shadow-lg border-0">
            <Card.Body>
              <h3 className="text-primary mb-3">Our Mission</h3>
              <Card.Text>
                At Emirates Star Coin, our mission is to empower individuals and
                businesses through accessible and transparent blockchain
                technology. We strive to create a robust ecosystem that fosters
                growth, investment, and sustainable development.
              </Card.Text>

              <h3 className="text-primary mb-3">Our Vision</h3>
              <Card.Text>
                We envision a future where digital currency is seamlessly
                integrated into everyday transactions, enabling financial
                freedom and inclusivity for all. By harnessing cutting-edge
                technology, we aim to bridge the gap between traditional finance
                and the digital economy.
              </Card.Text>

              <h3 className="text-primary mb-3">Our Values</h3>
              <ListGroup variant="flush" className="mb-3">
                <ListGroup.Item>
                  <strong>Integrity</strong>: We uphold the highest standards of
                  transparency and ethical practices.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Innovation</strong>: We continuously explore new
                  technologies to enhance our platform and user experience.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Community</strong>: We believe in building a
                  supportive community that encourages collaboration and
                  knowledge sharing.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Security</strong>: We prioritize the safety of our
                  users' assets through advanced security measures.
                </ListGroup.Item>
              </ListGroup>

              <h3 className="text-primary mb-3">Join Us</h3>
              <Card.Text>
                As we embark on this exciting journey, we invite you to join our
                community. Whether you are a seasoned investor or new to the
                cryptocurrency world, Emirates Star Coin offers a welcoming
                space to learn, grow, and thrive.
              </Card.Text>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
